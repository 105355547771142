<template>
  <div>
    <section class="d-md-none">
      <h3 class="mb-3">Familiari</h3>
      <b-button-group class="general_border mb-4">
        <b-button
          :variant="
            $route.params.section === 'main'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'familiari',
              params: { section: 'main' },
            })
          "
        >
          <span>Tutti</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'nuovo'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'familiari',
              params: { section: 'nuovo' },
            })
          "
        >
          <span>Nuovo</span>
        </b-button>
      </b-button-group>
    </section>
    <transition-group name="list" mode="out-in" appear>
      <div v-if="$route.params.section === 'main'" key="main">
        <FamiliariView />
      </div>
      <div v-else-if="$route.params.section === 'nuovo'" key="nuovo">
        <NuovoFamiliare />
      </div>
      <div v-else-if="$route.params.section === 'assistito'" key="assistito">
        <SchedaAssistito v-if="$route.params.hash"/>
        <span v-else>Paziente non trovato</span>
      </div>
      <div v-else-if="$route.params.section === 'profilo-assistito'" key="profilo-assistito">
        <ProfiloAnagraficoAssistito v-if="$route.params.hash"/>
        <span v-else>Paziente non trovato</span>
      </div>
    </transition-group>
  </div>
</template>
<script>
import FamiliariView from "@/components/familiari/FamiliariView.vue";
import NuovoFamiliare from "@/components/familiari/NuovoFamiliare.vue";
import SchedaAssistito from "@/components/familiari/SchedaAssistito.vue";
import ProfiloAnagraficoAssistito from "@/components/familiari/ProfiloAnagraficoAssistito.vue";
export default {
  name: "FamiliariMain",
  components: {
    FamiliariView,
    NuovoFamiliare,
    SchedaAssistito,
    ProfiloAnagraficoAssistito
  },
};
</script>
