<template>
  <div v-if="familiare" class="general_border w-100 d-flex h-100">
    <div class="puntofarmaCard p-4 p-lg-4 w-100 h-100">
      <b-row class="d-flex align-items-center justify-content-between">
        <div class="inline-block d-flex align-items-center">
          <b-icon icon="person-bounding-box" font-scale="3" class="mr-4">
          </b-icon>
          <h4 class="mb-0">{{ familiare.name }} {{ familiare.surname }}</h4>
        </div>
        <span class="point" @click="showDetails">
          <b-icon icon="pencil-fill" variant="primary"></b-icon>
        </span>
      </b-row>
      <b-row class="mt-4">
        <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
          <p class="text-left">
            <span class="text-primary">Fascia d'età </span><br /><span class="text-capitalize">{{ familiare.type
            }}</span>
          </p>
          <p class="text-left">
            <span class="text-primary">Tessera Sanitaria </span><span class="text-capitalize"><br />
              <template v-if="familiare.ts_number">
                {{ familiare.ts_number }}
              </template>
              <template v-else>Non inserita</template>
            </span>
          </p>
          <p class="text-left">
            <span class="text-primary">Genere <br /> </span><span class="text-capitalize">{{ familiare.gender }}</span>
          </p>
        </b-col>
        <b-col class="p-0 d-flex flex-column">
          <!-- <p class="text-left">
          <span class="text-primary">Nome: </span
          ><span class="text-capitalize">{{ familiare.name }}</span>
        </p>
        <p class="text-left">
          <span class="text-primary">Cognome: </span
          ><span class="text-capitalize">{{ familiare.surname }}</span>
        </p> -->
          <p class="text-left">
            <span class="text-primary">Relazione <br /></span>
            <span class="text-capitalize"> {{ familiare.relationship }} </span>
          </p>
          <p class="text-left">
            <span class="text-primary">Codice fiscale <br /> </span>
            <span class="text-capitalize"> {{ familiare.cf }} </span>
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FamiliareCard",
  props: ["familiare"],
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    showDetails() {
      // console.log(this.familiare);
      this.$emit("select-familiare", this.familiare);
    },
  },
};
</script>
<style>
.min__30 {
  min-height: 155px;
}
</style>