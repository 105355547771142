<template>
  <b-container fluid v-if="user" class="mb-4">
    <b-row :class="buttonGroupOpen ? 'min-width-75' : ''">
      <div class="text-left mt-3" :class="buttonGroupOpen ? 'w-100' : ''">
        <b-button-group
          class=""
          :class="buttonGroupOpen ? 'w-100' : ''"
          size="sm"
        >
          <!-- <h4 class="text-left mr-4 my-auto">Servizi</h4> -->
          <!-- <b-button key="b-prenotazioni" class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'prenotazioni'" :variant="
              activePanel === 'prenotazioni'
                ? 'secondary'
                : 'outline-secondary '
            ">
            <b-icon icon="app-indicator"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-prenotazioni-t">
              prenotazioni</span>
          </b-button> -->
          <b-button
            key="b-rilevazioni"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'rilevazioni'"
            :variant="
              activePanel === 'rilevazioni' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bar-chart"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-rilevazioni-t">
              rilevazioni</span
            >
          </b-button>
          <b-button
            key="b-patologie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'patologie'"
            :variant="
              activePanel === 'patologie' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bookmark"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-patologie-t"> patologie</span>
          </b-button>
          <b-button
            key="b-terapie"
            class="text-capitalize buttonbar custom-button-group-measures"
            @click="activePanel = 'terapie'"
            :variant="
              activePanel === 'terapie' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="bookmarks"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-terapie-t"> terapie</span>
          </b-button>
          <b-button
            key="b-note"
            class="text-capitalize buttonbar custom-button-group-measures general-right-border"
            @click="activePanel = 'note'"
            :variant="
              activePanel === 'note' ? 'secondary' : 'outline-secondary '
            "
          >
            <b-icon icon="clipboard"></b-icon><br />
            <span v-if="buttonGroupOpen" key="b-notes-t"> Note</span>
          </b-button>
          <span key="btab_handler" class="d-flex align-content-center">
            <b-icon
              key="btab_handler-ic"
              :icon="buttonGroupOpen ? ' arrow-bar-left' : 'arrow-bar-right'"
              font-scale="1.5"
              class="ml-2 my-auto point"
              @click="buttonGroupOpen = !buttonGroupOpen"
            >
            </b-icon>
          </span>
        </b-button-group>
      </div>
      <b-col
        class="col-12 col-md-12 col-xl-9 general_border text-center pr-xl-5 pt-2"
      >
      </b-col>
      <b-col> </b-col>
    </b-row>
    <b-container class="p-4 col-12 mt-4 shadow_2 bg-white general__border">
      <p
        class="m-0 p-0 text-left text-primary underlined point point"
        style="font-size: 1.5rem"
        @click="
          $router.push({
            name: 'familiari',
            params: {
              section: 'profilo-assistito',
              hash: user.hashid
            }
          })
        "
      >
        <b-avatar
          variant="white"
          :src="user.profile_pic"
          rounded="sm"
        ></b-avatar>
        <span class="ml-3 text-capitalize"
          >{{ user.anagrafica.name }} {{ user.anagrafica.surname }}
        </span>
        <span class="ml-2">
          <b-icon icon="file-earmark-person-fill" variant="primary"></b-icon>
        </span>
      </p>
      <hr />
      <b-row class="m-0 p-0 text-left">
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Genere </span>
          <span class="ml-1">{{ user.anagrafica.gender }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Regione </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.regione
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Indirizzo </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.address ? user.anagrafica.address : "Non indicato"
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Data di Nascita </span>
          <span class="ml-1" v-if="user.anagrafica.birthday"
            >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
          </span>
          <span class="ml-1" v-else>Non indicata</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Provincia </span>
          <span class="ml-1 text-capitalize">{{
            user.anagrafica.provincia
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Telefono </span>
          <span class="ml-1">{{
            user.anagrafica.tel_number
              ? user.anagrafica.tel_number
              : "Non indicato"
          }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Codice Fiscale </span>
          <span class="ml-1 text-uppercase">{{ user.anagrafica.cf }}</span>
        </b-col>
        <b-col class="col-6 col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Comune </span>
          <span class="ml-1 text-capitalize"
            >{{ user.anagrafica.comune }}
          </span>
        </b-col>
        <b-col class="col-sm-6 col-lg-4 col-md-6 m-0 p-0"
          ><span class="text-primary">Email </span>
          <span class="ml-1">{{ user.email }}</span>
        </b-col>
      </b-row>
    </b-container>
    <b-row cols="2" class="mb-4 mt-4 general_border">
      <div class="col-12 col-md-12 col-xl-9 general_border px-0 mt-0 pt-2">
        <transition-group
          name="slideside"
          mode="out-in"
          tag="div"
          class="pr-xl-5"
        >
          <b-row v-if="activePanel === 'rilevazioni'" key="rilevazioni">
            <RilevazioniComponent :subject="subject" />
            <!-- <ScoresComponent :subject="subject" :ascaregiver="true"/> -->
          </b-row>
          <b-row v-if="activePanel === 'patologie'" key="patologie">
            <PatologieComponent :subject="subject" />
            <EsenzioniComponent :subject="subject" />
          </b-row>
          <b-row v-if="activePanel === 'terapie'" key="terapie">
            <AdherenceIndex v-if="subject" />
            <TerapieComponent :subject="subject" />
          </b-row>
          <!-- <b-row v-if="activePanel === 'prenotazioni'" key="prenotazioni">
            <PrenotazioniPaziente :subject="subject" :userinfo="user" />
          </b-row> -->
          <b-row v-if="activePanel === 'note'" key="note" class="text-left">
            <PatientAnnotations
              :subjectHash="subject.hashid"
              :hidetop="false"
            />
          </b-row>
        </transition-group>
      </div>
      <div
        class="text-left d-none d-xl-block col-xl-3 p-2 long__txt"
        style="font-size: 1.15rem; letter-spacing: 0.05rem"
      >
        <div class="shadow_2 bg-white general__border p-3">
          <b-row cols="1" class="px-1 font-size-085rem">
            <p
              class="mb-0 text-left text-primary underlined point point"
              style="font-size: 1.5rem"
              @click="
                $router.push({
                  name: 'familiari',
                  params: {
                    section: 'profilo-assistito',
                    hash: user.hashid
                  }
                })
              "
            >
              <b-avatar
                variant="white"
                :src="user.profile_pic"
                rounded="sm"
              ></b-avatar>
              <span class="ml-3 text-capitalize"
                >{{ user.anagrafica.name }} {{ user.anagrafica.surname }}</span
              >
              <span class="ml-2">
                <b-icon
                  icon="file-earmark-person-fill"
                  variant="primary"
                ></b-icon>
              </span>
            </p>

            <hr class="my-3" />
            <b-col class="px-0 mb-2">
              <span class="text-primary">Genere <br /></span>
              <span>{{ user.anagrafica.gender }} </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Data di nascita</span> <br />
              <span v-if="user.anagrafica.birthday"
                >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
              </span>
              <span v-else>Non indicata</span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Codice Fiscale</span> <br />
              <span class="text-uppercase">{{ user.anagrafica.cf }} </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Regione</span> <br />
              <span class="text-capitalize"
                >{{ user.anagrafica.regione }}
              </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Provincia</span> <br />
              <span class="text-capitalize"
                >{{ user.anagrafica.provincia }}
              </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Comune</span> <br />
              <span class="text-capitalize">{{ user.anagrafica.comune }} </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Indirizzo</span> <br />
              <span class="text-capitalize"
                >{{
                  user.anagrafica.address
                    ? user.anagrafica.address
                    : "Non indicato"
                }}
              </span>
            </b-col>
            <b-col class="px-0 mb-2">
              <span class="text-primary">Telefono</span> <br />
              <span
                >{{
                  user.anagrafica.tel_number
                    ? user.anagrafica.tel_number
                    : "Non indicato"
                }}
              </span>
            </b-col>
            <b-col class="px-0 mb-4">
              <span class="text-primary">Email</span> <br />
              <span>{{ user.email }} </span>
            </b-col>
            <!-- <b-col class="mb-2">
            <strong>Indice Aderenza </strong><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="success"
              ></b-icon> </b-iconstack
            ><span class="ml-2">ottimale</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="warning"
              ></b-icon> </b-iconstack
            ><span class="ml-2">discreto</span><br />
            <b-iconstack font-scale="1.2">
              <b-icon stacked icon="circle-fill" variant="white"></b-icon>
              <b-icon
                stacked
                icon="circle"
                variant="danger"
              ></b-icon> </b-iconstack
            ><span class="ml-2">inadeguato</span>
          </b-col> -->
          </b-row>
        </div>
      </div>
    </b-row>
  </b-container>
  <h5 v-else-if="notFound">Utente Non trovato</h5>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { cittadinoService } from "@/_services"
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue"
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue"
import AdherenceIndex from "@/components/profile/sanitario/AdherenceIndex.vue"
import TerapieComponent from "@/components/pazienti/TerapieComponent.vue"
import PrenotazioniPaziente from "@/components/pazienti/PrenotazioniPaziente.vue"
import PatientAnnotations from "@/components/profile/sanitario/PatientAnnotations.vue"
import ScoresComponent from "@/components/profile/sanitario/ScoresComponent.vue"
import EsenzioniComponent from "@/components/profile/sanitario/EsenzioniComponent.vue"

export default {
  name: "SchedaAssistito",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    TerapieComponent,
    PrenotazioniPaziente,
    AdherenceIndex,
    PatientAnnotations,
    ScoresComponent,
    EsenzioniComponent
  },
  data() {
    return {
      user: null,
      activePanel: "rilevazioni",
      subject: null,
      notFound: false,
      buttonGroupOpen: true,
      availableSections: [
        "prenotazioni",
        "rilevazioni",
        "patologie",
        "terapie",
        "notifications"
      ]
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchUser() {
      var self = this
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.user = res.data.data
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app"
          }
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err)
          self.notFound = true
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    }
  },
  created() {
    this.fetchUser()
    if (
      this.$route.query.section &&
      this.availableSections.includes(this.$route.query.section)
    ) {
      this.activePanel = this.$route.query.section
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-button-group-measures {
  max-width: 150px;
  min-width: 50px;
  height: 50px;
}
</style>
