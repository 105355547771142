<template>
  <div class="col-12 pb-4" v-if="paziente">
    <b-row class="justify-content-between" align-v="center">
      <h2 class="text-left mb-0">Anagrafica Paziente</h2>
      <h5
        class="text-primary point"
        @click="
          $router.push({
            name: 'familiari',
            params: {
              section: 'assistito',
              hash: paziente.hashid,
            },
          })
        "
      >
        <span class="mx-1"> Scheda paziente</span>
        <b-icon icon="journal-medical"></b-icon>
      </h5>
    </b-row>
    <AnagraficaPazienteComponent :user="paziente" :asCaregiver="true" />
  </div>
</template>

<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import AnagraficaPazienteComponent from "@/components/profile/AnagraficaPazienteComponent.vue";

export default {
  name: "ProfiloAnagraficoAssistito",
  components: {
    AnagraficaPazienteComponent,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      paziente: null,
      subject: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchUser() {
      var self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.paziente = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
  },
  created() {
    this.fetchUser();
  },
  mounted() { },
};
</script>
