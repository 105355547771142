<template>
  <b-container fluid class="px-0 p-md-1 pb-4">
    <!-- <b-row cols="1" class="text-left mb-3">
      <router-link
        class="text-primary no__hover"
        :to="{ name: 'CittadinoHome' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Homepage</router-link
      >
    </b-row> -->
    <section class="familiari mb-5">
      <b-container fluid class="px-0 ml-3">
        <b-row
          cols="1"
          cols-md="2"
          class="justify-content-bewteen pb-2 ml-3"
          align-v="center"
        >
          <b-col class="align-self-end d-flex px-0">
            <h4 class="text-left py-1 mb-0">I tuoi familiari</h4>
            <b-icon
              class="ml-2 mb-4"
              icon="question-circle"
              variant="primary"
              id="familiari-explain"
            ></b-icon>
          </b-col>
          <b-col class="text-left text-md-right">
            <b-button
              :size="$root.isMobile ? 'sm' : ''"
              @click="
                $router.push({
                  name: 'familiari',
                  params: { section: 'nuovo' }
                })
              "
            >
              <b-icon icon="plus"></b-icon> nuovo assistito
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-tooltip
        :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
        variant="primary custom"
        target="familiari-explain"
        triggers="hover"
      >
        <p>
          Le persone non registrate in piattaforma che hai inserito come tuoi
          familiari.
        </p>

        <p>
          Clicca sul "+" per creare nuovi membri: una volta aggiunti potrai
          prenotare farmaci e servizi per loro.
        </p>
      </b-tooltip>

      <FamiliariComponent :loading="loading" />
    </section>

    <section class="mb-5">
      <div class="d-flex align-items-center">
        <h4 class="text-prim-grad-1 text-left pb-2 ml-3">I tuoi assistiti</h4>
        <b-icon
          class="ml-2 mb-4"
          icon="question-circle"
          variant="primary"
          id="assistiti-explain"
        ></b-icon>
      </div>

      <b-tooltip
        :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
        variant="primary custom"
        target="assistiti-explain"
        triggers="hover"
      >
        <p>
          Le persone che ti hanno scelto come loro caregiver.
          <br />
          Queste persone ti hanno dato il permesso di prenotare farmaci e
          servizi per conto loro.
        </p>
      </b-tooltip>
      <div
        v-if="associated.given && Object.keys(associated.given).length > 0"
        class="mb-5"
      >
        <b-row class="ml-n4 pt-2">
          <b-col
            v-for="(given, ind) in associated.given"
            :key="ind"
            class="px-0 pl-4 col-md-6 col-lgx-6 col-12"
          >
            <div
              v-if="given.pivot.enabled == 1"
              class="general_border pb-4 min__30"
            >
              <div class="puntofarmaCard p-4 p-lg-4">
                <b-row class="d-flex align-items-center">
                  <b-avatar
                    class="mr-4"
                    variant="white"
                    size="lg"
                    v-if="given.profile_pic"
                    :src="given.profile_pic"
                    rounded
                  >
                  </b-avatar>
                  <b-icon
                    v-else
                    icon="person-bounding-box"
                    font-scale="3"
                    class="mr-4"
                  >
                  </b-icon>
                  <h4 class="mb-0">
                    {{ given.anagrafica.name }} {{ given.anagrafica.surname }}
                  </h4>
                </b-row>
                <b-row class="mt-4">
                  <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Genere</span><br /><span
                        class="text-capitalize"
                        >{{ given.anagrafica.gender }}</span
                      >
                    </p>
                  </b-col>
                  <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Data di nascita</span
                      ><br /><span
                        v-if="given.anagrafica.birthday"
                        class="text-capitalize"
                        >{{
                          given.anagrafica.birthday | moment("DD/MM/YYYY")
                        }}</span
                      >
                      <span v-else>Non indicata</span>
                    </p>
                  </b-col>
                  <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Codice fiscale</span
                      ><br /><span class="text-uppercase">{{
                        given.anagrafica.cf
                      }}</span>
                    </p>
                  </b-col>
                  <b-col class="p-0 col-12 col-md-6 align-self-center">
                    <b-button
                      @click="
                        $router.push({
                          name: 'familiari',
                          params: {
                            section: 'assistito',
                            hash: given.hashid
                          }
                        })
                      "
                      size="sm"
                      class="mb-0 mt-auto"
                      >Vai al profilo sanitario</b-button
                    >
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-else class="general_border pt-4 pb-3 px-2 min__30 my-3">
              <p>
                <b-iconstack font-scale="5" class="shadow_7 rounded-circle">
                  <b-icon
                    class="shadow_7"
                    stacked
                    icon="circle-fill"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="person-x"
                    scale="0.5"
                    variant="secondary"
                  ></b-icon>
                </b-iconstack>
              </p>
              <p class="mb-0 fs-5 pt-1">
                <strong class=""
                  >{{ given.anagrafica.name }}
                  {{ given.anagrafica.surname }}</strong
                >
              </p>
              <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize"></p>
            </div>
          </b-col>
        </b-row>
      </div>
      <p v-else-if="!loading" class="text-center">
        Non ci sono assistiti registrati per questa utenza.
      </p>
    </section>

    <section class="mb-5">
      <div class="d-flex align-items-center">
        <h4 class="text-prim-grad-1 text-left pb-2 ml-3">I tuoi caregivers</h4>
        <b-icon
          class="ml-2 mb-4"
          icon="question-circle"
          variant="primary"
          id="caregivers-explain"
        ></b-icon>
      </div>

      <b-tooltip
        :placement="$root.isMobile ? 'bottom' : 'rightbottom'"
        variant="primary custom"
        target="caregivers-explain"
        triggers="hover"
      >
        <p>
          Le persone che si occupano di te. I soggetti di seguito elencati
          possono prenotare farmaci e servizi per conto tuo.
        </p>
      </b-tooltip>

      <div
        v-if="associated.givers && associated.givers.length > 0"
        class="mb-3"
      >
        <b-row class="ml-n4 pt-2">
          <b-col
            v-for="(givers, ind) in associated.givers"
            :key="ind"
            class="px-0 pl-4 col-md-6 col-lgx-6 col-12"
          >
            <div class="general_border pb-4 min__30">
              <div class="puntofarmaCard p-4 p-lg-4">
                <b-row class="d-flex align-items-center">
                  <b-avatar
                    class="mr-4"
                    variant="white"
                    size="lg"
                    v-if="givers.profile_pic"
                    :src="givers.profile_pic"
                    rounded
                  >
                  </b-avatar>
                  <b-icon
                    v-else
                    icon="person-bounding-box"
                    font-scale="3"
                    class="mr-4"
                  >
                  </b-icon>
                  <h4 class="mb-0">
                    {{ givers.anagrafica.name }} {{ givers.anagrafica.surname }}
                  </h4>
                </b-row>
                <b-row class="mt-4">
                  <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Genere</span><br /><span
                        class="text-capitalize"
                        >{{ givers.anagrafica.gender }}</span
                      >
                    </p>
                  </b-col>
                  <b-col class="p-0 col-12 col-md-6 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Data di nascita</span
                      ><br /><span
                        v-if="givers.anagrafica.birthday"
                        class="text-capitalize"
                        >{{
                          givers.anagrafica.birthday | moment("DD/MM/YYYY")
                        }}</span
                      >
                      <span v-else>Non indicata</span>
                    </p>
                  </b-col>
                  <b-col class="p-0 col-12 d-flex flex-column">
                    <p class="text-left">
                      <span class="text-primary">Codice fiscale</span
                      ><br /><span class="text-uppercase">{{
                        givers.anagrafica.cf
                      }}</span>
                    </p>
                  </b-col>
                </b-row>
                <p
                  v-if="givers.pivot.enabled == 1"
                  class="mb-0 mt-1 fs-5 text-font-grey text-capitalize"
                >
                  <b-button
                    class="btn mb-1 text-white btn-warning btn-sm collapsed w-50"
                    @click="openConfirmModal(givers, false)"
                    >Disabilita</b-button
                  >
                </p>
                <p v-else class="mb-0 mt-1 fs-5 text-font-grey text-capitalize">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1 collapsed px-2 w-50"
                    @click="openConfirmModal(givers, true)"
                    >Abilita</b-button
                  >
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <p v-else-if="!loading" class="text-center">
        Non ci sono caregivers registrati per questa utenza.
      </p>
    </section>

    <b-modal centered size="lg" id="confirm-op" hide-footer :title="modalTitle">
      <div v-if="selectedUser" class="text-center">
        <h5 class="">
          Vuoi {{ enabling ? "abilitare" : "disabilitare" }}
          {{ selectedUser.anagrafica.name }}
          {{ selectedUser.anagrafica.surname }} come tuo caregiver?
        </h5>

        <template v-if="enabling">
          <div class="my-2 px-0 px-md-2">
            <p class="text-center my-3 fw-bolder">
              Per procedere alla conferma è necessario leggere ed accettare
              l'informativa.
            </p>
            <div
              class="bg-light-panel border border-grey shadow_6 py-1 px-2 my-4"
              style="max-height: 50vh; overflow-y: scroll"
            >
              <InformativaCaregivers></InformativaCaregivers>
            </div>

            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informRead"
              :state="informRead == true"
            >
              Ho letto e compreso l’informativa privacy
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informAccepted"
              :state="informAccepted == true"
            >
              Acconsento al trattamento dei miei dati personali per le finalità
              indicate nell’informativa
            </b-form-checkbox>
          </div>
          <b-row class="justify-content-end mb-4 mt-3 pt-3">
            <b-button
              variant="info modal-footer-button"
              class="mx-2"
              @click="handleCancel"
            >
              No, Annulla
            </b-button>
            <b-button
              variant="outline-primary modal-footer-button"
              class="mx-2"
              :disabled="informAccepted == false || informRead == false"
              @click="handleConfirm"
            >
              Sì, Conferma
            </b-button>
          </b-row>
        </template>
        <b-row v-else class="justify-content-end mt-4 mb-2 pr-2 pt-3">
          <b-button
            variant="info"
            class="modal-footer-button mr-3"
            @click="handleCancel"
          >
            No, Annulla
          </b-button>
          <b-button
            variant="outline-primary"
            class="modal-footer-button"
            @click="handleConfirm"
          >
            Sì, Conferma
          </b-button>
        </b-row>
      </div>
      <h5 v-else>Nessun utente selezionato</h5>
    </b-modal>

    <b-modal
      centered
      size="lg"
      id="show-info"
      hide-footer
      :title="modalInfoTitle"
      @hidden="selectedUser = null"
    >
      <div v-if="selectedUser">
        <b-row cols="2" class="px-3">
          <b-col class="my-2 px-0 px-md-2">
            <strong>Nome </strong><br />
            <span
              >{{ selectedUser.anagrafica.name }}
              {{ selectedUser.anagrafica.surname }}
            </span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Genere </strong><br />
            <span>{{ selectedUser.anagrafica.gender }} </span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Data di nascita </strong><br />
            <span v-if="selectedUser.anagrafica.birthday"
              >{{ selectedUser.anagrafica.birthday | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>Non indicata</span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Codice fiscale </strong><br />
            <span>{{ selectedUser.anagrafica.cf }} </span>
          </b-col>
        </b-row>
      </div>
      <h5 v-else>Nessun utente selezionato</h5>
    </b-modal>
  </b-container>
</template>
<script>
import { cittadinoService } from "@/_services"
import { mapState, mapActions } from "vuex"
import FamiliariComponent from "@/components/familiari/FamiliariComponent.vue"
import InformativaCaregivers from "@/components/familiari/InformativaCaregivers.vue"

export default {
  name: "FamiliariView",
  components: {
    FamiliariComponent,
    InformativaCaregivers
  },
  data() {
    return {
      associated: {
        givers: null,
        given: null
      },
      enabling: false,
      informRead: false,
      informAccepted: false,
      loading: true,
      selectedUser: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    modalTitle: function () {
      return this.enabling ? "Abilita caregiver" : "Disabilita caregiver"
    },
    modalInfoTitle: function () {
      if (this.selectedUser) {
        return (
          this.selectedUser.anagrafica.name +
          " " +
          this.selectedUser.anagrafica.surname
        )
      }
      return ""
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    getCaregivers() {
      var self = this
      cittadinoService
        .getCaregivers()
        .then(function (res) {
          self.associated = res.data.data
          setTimeout(() => (self.loading = false), 100)
        })
        .catch(function (err) {
          // console.log(err);
          var msg = "Non è stato possibile recuperare le informazioni."
          window.scrollTo(0, 0)
          self.errorAlert(msg)
        })
    },
    openConfirmModal(selected, enabling) {
      this.selectedUser = selected
      this.enabling = enabling
      this.$bvModal.show("confirm-op")
    },
    openInfoModal(selected) {
      this.selectedUser = selected
      this.$bvModal.show("show-info")
    },
    handleCancel() {
      this.selectedUser = null
      this.enabling = false
      this.$bvModal.hide("confirm-op")
    },
    handleConfirm() {
      if (this.enabling) {
        this.enableUser()
      } else {
        this.disableUser()
      }
      this.$bvModal.hide("confirm-op")
    },
    enableUser() {
      var form = {
        user: this.selectedUser.hashid
      }
      var self = this
      cittadinoService
        .approveCaregiver(form)
        .then(function (res) {
          self.getCaregivers()
          window.scrollTo(0, 0)
          self.successAlert("Caregiver abilitato")
        })
        .catch(function (err) {
          // console.log(err);
          var msg = "Non è stato possibile effettuare l'operazione."
          window.scrollTo(0, 0)
          self.errorAlert(msg)
        })
    },
    disableUser() {
      var form = {
        user: this.selectedUser.hashid
      }
      var self = this
      cittadinoService
        .disapproveCaregiver(form)
        .then(function (res) {
          self.getCaregivers()
          window.scrollTo(0, 0)
          self.successAlert("Caregiver disabilitato")
        })
        .catch(function (err) {
          // console.log(err);
          var msg = "Non è stato possibile effettuare l'operazione."
          window.scrollTo(0, 0)
          self.errorAlert(msg)
        })
    }
  },
  created() {
    this.getCaregivers()
  }
}
</script>
<style lang="scss">
.b-tooltip.custom .tooltip-inner {
  min-width: 250px;
  padding: 18px 18px 0px !important;
  text-align: justify !important;
  text-justify: auto !important;
  line-height: 200% !important;
  background-color: rgba(255, 255, 255, 1) !important;
  color: $primary !important;
  opacity: 1 !important;
  border: 1px solid $whitesmoke;
  box-shadow: 0px 0px 14px 5px rgba(0, 0, 0, 0.15);
  // border: 1px solid $primary !important;
}

.b-tooltip.custom {
  text-justify: inter-word;
  display: flex;
  align-items: center;
  opacity: 1 !important;
}
</style>
