<template>
  <b-container fluid class="m-0 p-0">
    <div class="mb-3">
      <b-row
        cols="1"
        cols-md="2"
        class="justify-content-bewteen pb-2"
        align-v="center"
      >
        <b-col class="align-self-end px-0">
          <h4 class="text-left py-1 mb-0">Questionari</h4>
        </b-col>
        <b-col class="text-center my-3 my-md-0 text-md-right">
          <b-button @click="$bvModal.show('add-score')">
            <b-icon icon="plus"></b-icon> nuova rilevazione questionario
          </b-button>
        </b-col>
      </b-row>
      <b-row
        align-v="stretch"
        cols="1"
        cols-sm="2"
        cols-md="3"
        cols-lg="4"
        class="my-2 ml-n2 col-12 px-0 align-items-center align-content-center"
      >
        <b-col
          class="px-2 py-2"
          v-for="(scoreValues, scoreName) in scores"
          :key="scoreName"
        >
          <div
            @click="selectScore(scoreValues[0])"
            class="general_border pb-4 pt-3 point puntofarmaCard col-12 d-flex flex-column align-items-center justify-content-center h-100"
            :class="
              scoreValues[0].threshold_level !== null
                ? 'border border__15__2 border-' +
                  valueLevelColors[Math.abs(scoreValues[0].threshold_level)]
                : ''
            "
          >
            <p class="mb-0 fs-5 pt-1">
              {{ scoreName }}
            </p>
            <h4 class="mb-0 pt-1" v-if="scoreValues[0].value != null">
              <strong class="">{{ scoreValues[0].value * 1 }}</strong>
            </h4>
            <br v-else />
            <p class="mb-0 mt-1 fs-5 text-primary text-capitalize">
              {{ formatData(scoreValues[0].created_at) }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-modal
        centered
        scrollable
        class="text-center"
        size="lg"
        id="detail-score"
        hide-footer
        body-class="pt-1"
      >
        <template #modal-title>
          <h4 class="pb-0" v-if="selectedScore && selectedScore.score">
            {{ selectedScore.score.name }}
          </h4>
          <h4 v-else>Storico</h4>
        </template>
        <div
          class="text-center"
          v-if="selectedScore && selectedScore.score.name"
        >
          <template v-if="!aspatient">
            <div class="bg-mdm-light-grey px-2 pt-1 general_border">
              <h6 class="text-left">
                Ultimo punteggio registrato: {{ selectedScore.value * 1 }}
              </h6>
              <p
                v-if="
                  selectedScore.score_reference &&
                  selectedScore.score_reference.description
                "
                class="text-justify"
              >
                {{ selectedScore.score_reference.description }}
              </p>
            </div>
            <div class="col-12 px-0">
              <FrequenzaValutazioneScore
                :subject="subject.hashid"
                :score="selectedScore.score.hashid"
                @close-editmodal="$bvModal.hide('reference-modal')"
                @need-update="closeAndUpdate = true"
              ></FrequenzaValutazioneScore>
            </div>
          </template>
          <div v-if="scoreHistory">
            <!-- filters -->
            <b-col cols="12">
              <b-row
                cols="2"
                cols-lg="4"
                class="text-left justify-content-between my-2"
              >
                <b-form-group class="mx-1">
                  <label for="" class="fw-bolder">Anno</label>
                  <b-form-select
                    v-model="historyFilters.year"
                    @change="getHistoryData"
                  >
                    <b-form-select-option :value="null"
                      >tutti</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="year in historyFilterOptions.year"
                      :value="year + 2021"
                      >{{ year + 2021 }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mx-1">
                  <label for="" class="fw-bolder">Mese</label>
                  <b-form-select
                    v-model="historyFilters.month"
                    @change="getHistoryData"
                  >
                    <b-form-select-option :value="null"
                      >tutti</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="month in historyFilterOptions.month"
                      :value="month.value"
                      >{{ month.label }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
                <b-form-group class="mx-1">
                  <label for="" class="fw-bolder">Giorno</label>
                  <b-form-select
                    v-model="historyFilters.day"
                    @change="getHistoryData"
                  >
                    <b-form-select-option :value="null"
                      >tutti</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="day in historyFilterOptions.day"
                      :value="day"
                      >{{ day }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-row>
            </b-col>
            <!-- filters -->
            <!-- CHART -->
            <LineChart
              :title="selectedScore.score.name"
              :idappend="selectedScore.hashid + '-line-chart'"
              :datatoshow="chartData"
              :chartlabels="chartLabels"
            />
            <!-- CHART -->
            <h5 class="mt-4 mb-2 text-left">Valori rilevati</h5>
            <div class="fixed__box__sm">
              <div
                class="my-2 text-left d-flex flex-wrap align-items-center justify-content-between"
                v-for="(hist, ind) in scoreHistory"
                :key="ind"
              >
                <span class="mt-2 text-capitalize text-primary">
                  {{ formatData(hist.created_at) }}
                </span>
                <span v-if="hist.value != null">
                  <span>
                    <span v-if="hist.threshold_level !== null">
                      <b-icon
                        class="mr-1"
                        icon="circle-fill"
                        :variant="
                          valueLevelColors[Math.abs(hist.threshold_level)]
                        "
                      ></b-icon>
                    </span>
                    <strong class="">{{ hist.value * 1 }}</strong>
                  </span>
                  <span
                    v-if="hist.filled_survey"
                    title="vedi questionario"
                    class="mx-2 point"
                    @click="openSurveyModal(hist.filled_survey)"
                  >
                    <b-iconstack font-scale="1.3">
                      <b-icon stacked icon="zoom-in" variant="primary"></b-icon>
                    </b-iconstack>
                  </span>
                </span>
              </div>
            </div>
            <div v-if="scoreHistory.length == 0" class="my-2 py-2 text-left">
              <p class="text-font-grey">Nessun valore registrato</p>
            </div>
          </div>
          <template v-if="selectedScore.score.scoreable">
            <b-button
              v-if="aspatient"
              @click="
                $router.push({
                  name: 'score questionario',
                  params: {
                    quest: selectedScore.score.scoreable.hashid,
                  },
                })
              "
              variant="outline-primary"
              class="modal-footer-button"
            >
              <b-icon icon="plus" class="mr-1"></b-icon>aggiungi
            </b-button>
            <b-button
              v-else
              @click="
                $router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'somministra',
                    quest: selectedScore.score.scoreable.hashid,
                  },
                  query: {
                    user: subject.hashid,
                  },
                })
              "
              variant="outline-primary"
              class="modal-footer-button"
            >
              <b-icon icon="plus" class="mr-1"></b-icon>aggiungi
            </b-button>
          </template>
        </div>
      </b-modal>
      <b-modal
        title="Esito questionario"
        id="filled-surv-modal"
        size="lg"
        scrollable
        hide-footer
        centered
      >
        <template v-if="surveyToShow">
          <FilledSurveyShower
            :response="surveyToShow.response"
            :score="surveyToShow.score"
          ></FilledSurveyShower
        ></template>
      </b-modal>
      <b-modal
        id="add-score"
        title="Nuova rilevazione questionario"
        size="lg"
        scrollable
        hide-footer
        centered
      >
        <div>
          <b-row cols="1" cols-md="2" cols-xl="3" v-if="!selectedExam">
            <b-col>
              <b-row cols="1" class="leveled__rows">
                <b-col
                  class="text-center text-white fw-bolder bg-secondary general_border pb-2"
                  >Trova score</b-col
                >
                <b-col
                  class="shadow_1 border border-light general_border bg-white"
                >
                  <b-input-group>
                    <b-form-input
                      placeholder="Cerca..."
                      style="box-shadow: none !important"
                      size="sm"
                      class="border-0 shadow_0"
                      type="search"
                      v-model="filters.search"
                    ></b-form-input>
                    <template #append>
                      <span class="m-2">
                        <b-icon
                          icon="search"
                          variant="secondary"
                          font-scale="1.5"
                        ></b-icon>
                      </span>
                    </template>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="scoreList.length > 0" class="pb-4">
            <transition-group
              tag="div"
              name="list"
              mode="out-in"
              appear
              class="col-12 pl-0 d-flex flex-wrap justify-content-center align-items-stretch"
            >
              <div
                class="my-1 col-12 col-md-6 col-lgx-5 px-0 px-md-1"
                v-for="(exam, ind) in scoreList"
                :key="exam.hashid"
              >
                <b-button
                  block
                  v-if="
                    !selectedExam ||
                    (selectedExam && selectedExam.hashid == exam.hashid)
                  "
                  :variant="
                    selectedExam && selectedExam.hashid == exam.hashid
                      ? 'secondary'
                      : 'outline-secondary'
                  "
                  class="text-break m-2 text-capitalize font-weight-bold py-4 h-100 min__h__ril"
                  @click="selectedExam = exam"
                  >{{ exam.name }}</b-button
                >
              </div>
            </transition-group>
          </b-row>
          <b-row v-if="selectedExam" class="px-3 py-2 justify-content-end mt-4">
            <b-button
              class="modal-footer-button mx-1"
              variant="info"
              @click="selectedExam = null"
              >indietro</b-button
            >
            <b-button
              v-if="aspatient"
              @click="
                $router.push({
                  name: 'score questionario',
                  params: {
                    quest: selectedExam.scoreable.hashid,
                  },
                })
              "
              variant="outline-primary"
              class="modal-footer-button mx-1"
            >
              <b-icon icon="plus" class="mr-1"></b-icon>somministra questionario
            </b-button>
            <b-button
              v-else-if="ascaregiver"
              @click="
                $router.push({
                  name: 'score questionario',
                  params: {
                    quest: selectedExam.scoreable.hashid,
                  },
                  query: {
                    user: subject.hashid,
                  },
                })
              "
              variant="outline-primary"
              class="modal-footer-button mx-1"
            >
              <b-icon icon="plus" class="mr-1"></b-icon>somministra questionario
            </b-button>

            <b-button
              v-else
              @click="
                $router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'somministra',
                    quest: selectedExam.scoreable.hashid,
                  },
                })
              "
              variant="outline-primary"
              class="modal-footer-button mx-1"
            >
              <b-icon icon="plus" class="mr-1"></b-icon>somministra questionario
            </b-button>
          </b-row>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { monitoringService } from "@/_services";
import { mapState, mapActions } from "vuex";
import { MONTHS } from "@/_utils/constants.js";
import RefertoUploader from "@/components/utilities/RefertoUploader.vue";
import LineChart from "@/components/profile/sanitario/LineChart.vue";
import FrequenzaValutazioneScore from "@/components/profile/sanitario/FrequenzaValutazioneScore.vue";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "ScoresComponent",
  props: ["subject", "aspatient", "ascaregiver"],
  components: {
    RefertoUploader,
    LineChart,
    FrequenzaValutazioneScore,
    FilledSurveyShower,
  },
  data() {
    return {
      scores: null,
      filters: {
        search: null,
      },
      historyFilters: {
        year: null,
        month: null,
        day: null,
      },
      exams: null,
      selectedExam: null,
      selectedScore: null,
      scoreHistory: null,
      chartData: [],
      chartLabels: [],
      valueLevelColors: {
        0: "success",
        1: "sec-3",
        2: "warning",
        3: "danger",
      },
      closeAndUpdate: false,
      historyFilterOptions: {
        year: null,
        // month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        month: MONTHS,
        day: 31,
      },
      surveyToShow: null,
      scoreList: [],
    };
  },
  watch: {
    "historyFilters.month": function (value) {
      const dayCount = moment().set("month", value - 1);
      this.$nextTick(function () {
        this.historyFilterOptions.day = dayCount.daysInMonth();
      });
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchScores() {
      this.getScores();
      if (this.subject) {
        return this.fetchUserScores();
      }
      // this.fetchOwnMeasurements();
    },
    getScores() {
      const self = this;
      monitoringService
        .getScoreList(this.filters)
        .then(function (response) {
          self.scoreList = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchUserScores() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      monitoringService
        .getUserScores(this.subject.hashid, filters)
        .then(function (response) {
          self.scores = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    getHistoryData() {
      this.scoreHistory = null;
      const self = this;
      monitoringService
        .getUserScoresHistory(
          this.subject.hashid,
          this.selectedScore.score.hashid,
          this.historyFilters
        )
        .then(function (response) {
          self.scoreHistory = response.data.data;
          self.setChartData();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    setChartData() {
      this.chartData = [];
      this.chartLabels = [];
      const self = this;
      this.scoreHistory.map(function (el) {
        self.chartData.unshift(el.value);
        let addedAt = moment(el.created_at).format("DD/MM");
        self.chartLabels.unshift(addedAt);
      });
    },
    openSurveyModal(survey) {
      this.surveyToShow = survey;
      this.$bvModal.show("filled-surv-modal");
    },
    formatData(data) {
      return moment(data).format("DD MMMM YYYY HH:mm");
    },
    selectScore(meas) {
      this.selectedScore = meas;
      this.$bvModal.show("detail-score");
      this.getHistoryData();
    },
    setFilterOptions() {
      this.historyFilterOptions.year = moment().diff(
        moment("01-01-2021", "DD-MM-YYYY"),
        "years"
      );
      const now = moment();
      // this.historyFilters.day = now.date()
      this.historyFilters.month = now.month() + 1;
      this.historyFilters.year = now.year();
    },
  },
  created() {
    this.fetchScores();
  },
  mounted() {
    // this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
    //   // console.log("Modal is about to be shown", bvEvent, modalId);
    // });
    this.setFilterOptions();
  },
};
</script>
<style>
.min__h__ril {
  min-height: 10vh;
}
</style>
